import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
// import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import showNotification from '../../../components/extras/showNotification';
import AceLogo from '../../../assets/img/acefordogs-logo.png';
import { StaffLogin } from '../../../common/data/getData';
import { demoPages } from '../../../menu';

const validate = (values) => {
	const errors = {};

	if (!values.userName) {
		errors.userName = 'user name is required';
	}

	if (!values.password) {
		errors.password = 'password is required';
	}

	return errors;
};

const Login = () => {
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	const user = JSON.parse(localStorage.getItem('authed'));
	useEffect(() => {
		if (user && user.jwtToken) {
			if (user.userType === 3) {
				navigate('/staffdashboard');
			} else if (user.userType === 4) {
				navigate('/driver');
			} else {
				navigate('/dashboard');
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function postData(values) {
		StaffLogin(values)
			.then((res) => {
				console.log(res.data);
				localStorage.setItem('authed', JSON.stringify(res.data));
				if (res.data.userType === 3) {
					navigate('/staffdashboard');
				} else if (res.data.userType === 4) {
					navigate('/driver');
				}
			})
			.catch((error) => {
				console.log(error);
				showNotification(
					<span className='d-flex align-items-center'>
						<span>{error.response.data.message || error.response.data.Message}</span>
					</span>,
					'',
					'danger',
				);
			});
	}

	const formik = useFormik({
		initialValues: {
			userName: '',
			password: '',
		},
		onSubmit: (values) => {
			postData({
				userName: values.userName,
				password: values.password,
			});
		},
		validate,
	});

	return (
		<PageWrapper title='Login' className='bg-info'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div
									className='text-center mt-2 fw-bold'
									style={{ color: '#12614b', fontSize: 16 }}>
									Staff / Driver login
								</div>
								<div className='text-center my-5'>
									<div
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										{/* <Logo width={200} /> */}
										<img src={AceLogo} alt='logo' width={200} />
									</div>
								</div>

								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup id='userName' isFloating label='Username'>
											<Input
												type='text'
												onChange={formik.handleChange}
												value={formik.values.userName}
												isValid={formik.isValid}
												isTouched={formik.touched.userName}
												invalidFeedback={formik.errors.userName}
												validFeedback='Looks good!'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='password' isFloating label='Password'>
											<Input
												type='password'
												onChange={formik.handleChange}
												value={formik.values.password}
												isValid={formik.isValid}
												isTouched={formik.touched.password}
												invalidFeedback={formik.errors.password}
												validFeedback='Looks good!'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<div className='text-end'>
											<Link to={demoPages.forgotPassword.path}>
												Forgot password?{' '}
											</Link>
										</div>
									</div>
									<div className='col-12'>
										<Button
											color='info'
											className='w-100 py-3'
											onClick={formik.handleSubmit}>
											Login
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
