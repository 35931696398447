import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
// import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import showNotification from '../../../components/extras/showNotification';
import AceLogo from '../../../assets/img/acefordogs-logo.png';
import { resetPassword } from '../../../common/data/getData';

const validate = (values) => {
	const errors = {};

	if (!values.password) {
		errors.password = 'password is required';
	}
	if (!values.confirmPassword) {
		errors.userName = 'confirm password is required';
	}

	return errors;
};

const ResetPassword = () => {
	const { token } = useParams();
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();
	function postData(values) {
		if (values.password !== values.confirmPassword) {
			showNotification(
				<span className='d-flex align-items-center'>
					<span>Password and confirm password not match</span>
				</span>,
				'',
				'danger',
			);
			return;
		}
		resetPassword(values)
			.then(() => {
				showNotification(
					<span className='d-flex align-items-center'>
						<span>Successfully saved</span>
					</span>,
					'',
					'success',
				);
				navigate('/');
			})
			.catch((error) => {
				console.log(error);
				showNotification(
					<span className='d-flex align-items-center'>
						<span>{error.response.data.message || error.response.data.Message}</span>
					</span>,
					'',
					'danger',
				);
			});
	}

	const formik = useFormik({
		initialValues: {
			token: '',
			password: '',
			confirmPassword: '',
		},
		onSubmit: (values) => {
			postData({
				token,
				password: values.password,
				confirmPassword: values.confirmPassword,
			});
		},
		validate,
	});

	return (
		<PageWrapper title='Login' className='bg-info'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<div
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										{/* <Logo width={200} /> */}
										<img src={AceLogo} alt='logo' width={200} />
									</div>
								</div>

								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup id='password' isFloating label='New password'>
											<Input
												type='password'
												onChange={formik.handleChange}
												value={formik.values.password}
												isValid={formik.isValid}
												isTouched={formik.touched.password}
												invalidFeedback={formik.errors.password}
												validFeedback='Looks good!'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup
											id='confirmPassword'
											isFloating
											label='Confirm password'>
											<Input
												type='password'
												onChange={formik.handleChange}
												value={formik.values.confirmPassword}
												isValid={formik.isValid}
												isTouched={formik.touched.confirmPassword}
												invalidFeedback={formik.errors.confirmPassword}
												validFeedback='Looks good!'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='info'
											className='w-100 py-3'
											onClick={formik.handleSubmit}>
											Submit
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ResetPassword;
