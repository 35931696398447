import React from 'react';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
// import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import showNotification from '../../../components/extras/showNotification';
import AceLogo from '../../../assets/img/acefordogs-logo.png';
import { forgotPassword } from '../../../common/data/getData';
import { demoPages } from '../../../menu';

const validate = (values) => {
	const errors = {};

	if (!values.email) {
		errors.email = 'email is required';
	}

	return errors;
};

const ForgotPassword = () => {
	const { darkModeStatus } = useDarkMode();

	function postData(values) {
		forgotPassword(values)
			.then((res) => {
				console.log(res);
				showNotification(
					<span className='d-flex align-items-center'>
						<span>Check your inbox for reset link</span>
					</span>,
					'',
					'success',
				);
			})
			.catch((error) => {
				console.log(error);
				showNotification(
					<span className='d-flex align-items-center'>
						<span>{error.response.data.message || error.response.data.Message}</span>
					</span>,
					'',
					'danger',
				);
			});
	}

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		onSubmit: (values) => {
			postData({
				email: values.email,
			});
		},
		validate,
	});

	return (
		<PageWrapper title='Login' className='bg-info'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div
									className='text-center mt-2 fw-bold'
									style={{ color: '#12614b', fontSize: 16 }}>
									Forgot your password?
								</div>
								<div className='text-center my-5'>
									<div
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										{/* <Logo width={200} /> */}
										<img src={AceLogo} alt='logo' width={200} />
									</div>
								</div>

								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='email'
											isFloating
											label='Enter email connected to your account'>
											<Input
												type='text'
												onChange={formik.handleChange}
												value={formik.values.email}
												isValid={formik.isValid}
												isTouched={formik.touched.email}
												invalidFeedback={formik.errors.email}
												validFeedback='Looks good!'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<div className='text-end'>
											<Link to={demoPages.login.path}>Back to login </Link>
										</div>
									</div>
									<div className='col-12'>
										<Button
											color='info'
											className='w-100 py-3'
											onClick={formik.handleSubmit}>
											Send password reset link
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ForgotPassword;
